import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    GatsbyImage, getImage
} from 'gatsby-plugin-image';

const BgPicture = styled(GatsbyImage)`
position: absolute !important;
top: 0;
left: 0;
z-index: -1;
width: 100%;
`;

const BackgroundPicture = ({
    image, alt
}) => (
    <BgPicture
        image={getImage(image)}
        alt={alt}
    />
);

BackgroundPicture.propTypes = {
    image: PropTypes.object,
    alt: PropTypes.string
};


export default React.memo(BackgroundPicture);
