import React, {
    useState, useRef, useEffect
} from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import _SectionClients from '../components/SectionClients';

const scaleFade = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.6);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 7% 0%;

    @media (max-width: 767px) {
        margin:25% auto 10% auto !important;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    color: #434343;
    padding: 3% 9%;

    @media (max-width: 767px) {
        flex-direction:column !important;
        padding: 10% !important;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        padding: 5% 10%;
    }
`;

const LeftCell = styled.div`
    width: 45%;
    flex-grow: 0;
    flex-shrink: 0;

    @media (max-width: 767px) {
        width:100% !important;
        order: 2;
    }
`;

const LeftCellTitle = styled.h2`
    font-size: 24px;
    line-height: 34px !important;
    font-weight: 200;
    margin: 0 0;
`;

const LeftCellText = styled.p`
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
`;

const SubCellIcons = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    flex-wrap:wrap;
    margin-top:5%;
`;

const RightCell = styled.div`
    margin-top: -10%;
    margin-bottom: -15%;
    position: relative;
    max-width: 55%;
    margin-left: 5%;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 767px) {
        max-width: 100%;
        margin-left: 0%;
        margin-top: -25% !important;
        margin-bottom: auto !important;
    }
`;

const PictureRight = styled(GatsbyImage)`
    max-width:93%;
    position: relative;
    z-index: 1;
    top: -6px;
    max-height: ${({ imageHeight }) => imageHeight ? `${imageHeight}px` : '600px'};

    @media (max-width: 767px) {
        margin: 0 auto;
        max-height: ${({
        imageHeight, hasMockup
    }) => imageHeight ? `${imageHeight / (hasMockup ? 1 : 2)}px` : '400px'};
    }
`;

const SectionClients = styled(_SectionClients)`
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
`;

const IconMoon = styled.i`
    color: #227EE6;
    margin-right:6%;

    @media (max-width: 767px) {
        width:2vh;
        margin-right:10%;
    }
`;

const TextIcon = styled.p`
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
`;

const Video = styled.video`
    position: absolute;
    top: 48%;
    left: 43%;
    width: 67%;
    z-index: 1;
    margin-left: 5%;
    transform: translate(-50%, -50%);
    @media (max-width: 767px) {
        width:68%;
        left:40%;
    }

    ${({ $hasPictureRight }) => !$hasPictureRight ? `
    position: relative;
    transform: initial;
    width: 115%;
    left: 13%;

    @media (min-width: 768px) and (max-width: 1024px) {
        width: 110%;
    }

    @media (max-width: 767px) {
        width: 100%;
        left: initial;
        margin-bottom: 10%;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    }
` : null}
`;

const VideoKiosk = styled.video`
    position: absolute;
    top: 38%;
    left: 50%;
    width: 62%;
    z-index: 1;
    margin-left: 5%;
    transform: translate(-50%, -50%);
    @media (max-width: 767px) {
        width:61%;
        left:46%;
    }
`;

const VideoIphoneOld = styled.video`
    position: absolute;
    top: 48%;
    left: 49%;
    width: 84%;
    z-index: 1;
    margin-left: 5%;
    transform: translate(-50%, -50%);
    @media (max-width: 767px) {
        width:84%;
        left:45%;
    }
`;

const VideoTablette = styled.video`
    position: absolute;
    top: 45%;
    left: 50%;
    width: 81%;
    z-index: 2;
    margin-left: 5%;
    transform: translate(-50%, -50%);
`;

const PlayButton = styled.span`
    background-color: #217ee6;
    position: absolute;
    box-shadow: 0 3px 20px rgba(9, 72, 155, .6);
    height: 64px;
    width: 64px;
    top: 50%;
    left: 45%;
    z-index: 2;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: all;
    transition: all .4s;

    &:hover {
        transform: scale(1.1);
    }
`;

const PlayButtonImg = styled.img`
  width: 40%;
  position: absolute;
  top: 28%;
  left: 35%;
`;

const CloseVideo = styled.span`
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    top: -30px;
    right: -50px;

    &:before,
    &:after {
        content: '';
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        width: 40px;
        height: 6px;
        border-radius: 4px;
    }

    &:before {
        transform: rotate(45deg);
    }
    
    &:after {
        transform: rotate(-45deg);
    }
`;

const VideoOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10001;
    background-color: rgba(0, 0, 0, 0.6);
`;

const VideoContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    max-width: 1200px;
    z-index: 10002;
`;

const VideoPlayable = styled.video`
    width: 100%;
    height: 100%;
    max-height: 90vh;

    animation: ${scaleFade} 0.4s ease;
`;

const Paragraph = ({ text }) => {
    return (
        <LeftCellText dangerouslySetInnerHTML={{ __html: text }}></LeftCellText>
    );
};

Paragraph.propTypes = {text: PropTypes.string};

const IconsCell = ({
    icon, text
}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%'
        }}>
            <IconMoon className={icon} />
            <TextIcon dangerouslySetInnerHTML={{ __html: text }}></TextIcon>
        </div>
    );
};

IconsCell.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string
};

const SectionOnlyFooter = ({
    titleSection,
    pictureRight,
    altDescription,
    urlFooter,
    titleButton,
    iconsOnlyFooterComponent,
    videoUrl4,
    videoUrl3,
    videoUrl2,
    videoUrl,
    customersLogos,
    playButtonUrl,
    phraseLogo,
    cellParagraph = null
}) => {
    const [videoPlayed, setVideoPlayed] = useState(false);
    const videoRef = useRef();

    const startVideo = () => {
        setVideoPlayed(true);
    };

    const closeVideo = (e = {}) => {
        if (e.target !== videoRef.current) {
            setVideoPlayed(false);
        }
    };

    useEffect(() => {
        if (videoPlayed && videoRef.current) {
            videoRef.current.play();
            const onVideoEnds = () => {
                videoRef.current.removeEventListener('ended', onVideoEnds);
                closeVideo();
            };

            videoRef.current.addEventListener('ended', onVideoEnds);
        }
    }, [videoPlayed]);

    const hasMockup = Boolean(videoUrl || videoUrl2 || videoUrl3 || videoUrl4);
    const iconsCell = iconsOnlyFooterComponent ? iconsOnlyFooterComponent.map(i => {
        return (<IconsCell key={i.icon + i.text}
            icon={i.icon}
            text={i.text} />);
    }) : null;

    const imageHeight = pictureRight && pictureRight.gatsbyImageData && pictureRight.gatsbyImageData.height ? pictureRight.gatsbyImageData.height : null;

    return (
        <Layout>

            <Content>
                <LeftCell>
                    <LeftCellTitle dangerouslySetInnerHTML={{ __html: titleSection }}></LeftCellTitle>
                    {cellParagraph ? <Paragraph text={cellParagraph} /> : null}
                    <SubCellIcons>
                        {iconsCell}
                    </SubCellIcons>
                </LeftCell>
                <RightCell small={hasMockup}>
                    {pictureRight ? <PictureRight hasMockup={hasMockup}
                        imageHeight={imageHeight}
                        imgStyle={{ objectFit: 'contain' }}
                        image={pictureRight.gatsbyImageData}
                        alt={altDescription} /> : null}

                    {playButtonUrl ? <PlayButton onClick={startVideo}
                        target="_blank">
                        <PlayButtonImg src="/images/products/play-button.png"/>
                    </PlayButton>
                        : null}

                    {videoUrl ? <Video autoPlay
                        loop
                        muted
                        playsInline
                        $hasPictureRight={pictureRight}
                        src={videoUrl} /> : null}

                    {videoUrl2 ? <VideoTablette autoPlay
                        loop
                        muted
                        playsInline
                        src={videoUrl2} /> : null}

                    {videoUrl3 ? <VideoIphoneOld autoPlay
                        loop
                        muted
                        playsInline
                        src={videoUrl3} /> : null}

                    {videoUrl4 ? <VideoKiosk autoPlay
                        loop
                        muted
                        playsInline
                        src={videoUrl4} /> : null}
                </RightCell>
            </Content>
            <SectionClients
                urlFooter={urlFooter}
                titleButton={titleButton}
                customersLogos={customersLogos}
                phraseLogo={phraseLogo}
            />
            {videoPlayed && (
                <VideoOverlay onClick={closeVideo}>
                    <VideoContainer>
                        <VideoPlayable
                            ref={videoRef}
                            src={playButtonUrl}
                            controls
                            muted
                            playsInline></VideoPlayable>
                        <CloseVideo onClick={closeVideo} />
                    </VideoContainer>
                </VideoOverlay>
            )}
        </Layout>
    );
};

SectionOnlyFooter.defaultProps = {icons: []};

SectionOnlyFooter.propTypes = {
    titleSection: PropTypes.string,
    pictureRight: PropTypes.object,
    altDescription: PropTypes.string,
    urlFooter: PropTypes.string,
    titleButton: PropTypes.string,
    videoUrl4: PropTypes.string,
    videoUrl3: PropTypes.string,
    videoUrl2: PropTypes.string,
    videoUrl: PropTypes.string,
    cellParagraph: PropTypes.string,
    phraseLogo: PropTypes.string,
    customersLogos: PropTypes.arrayOf(PropTypes.object),
    playButtonUrl: PropTypes.string,
    iconsOnlyFooterComponent: PropTypes.arrayOf(PropTypes.object)
};

export default React.memo(SectionOnlyFooter);
