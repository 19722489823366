import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    GatsbyImage, getImage
} from 'gatsby-plugin-image';
import LocalizedLink from './LocalizedLink';

import PrimaryButton from '../components/PrimaryButton';

const Cell = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 320px;
    overflow: hidden;
    border-radius: 15px;
    position:relative;
    margin:7% 0%;


    @media (min-width: 320px) and (max-width: 767px){
        height:200px;
    }
`;

const PictureBanner = styled(GatsbyImage)`
    z-index: 0;
    width: 100%;
    height:100%;

    @media (min-width: 320px) and (max-width: 1024px){
        width: 200%;
        filter: brightness(70%);
    }
`;

const CellContent = styled.div`
    position: absolute;
    left: 6%;
    width: 50%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    overflow-wrap:break-word;

    @media (min-width: 320px) and (max-width: 1024px){
        left:8%;
        width:80%;
    }
`;

const Title = styled.h3`
    font-size: 40px;
    font-weight: 200;
    color: ${props => props.titleColor === 'white' ? '#ffffff' : '#434343'};
    padding: 0;
    margin: 0 0 30px;

    @media (min-width: 320px) and (max-width: 767px){
        font-size:23px;
        margin:0px;
    }
`;

const BannerPicture = ({
    titleBannerPicture, titleButton, color, backgroundBanner, buttonUrl, alt
}) => {

    return (
        <Cell>
            <CellContent>
                <Title
                    dangerouslySetInnerHTML={{ __html: titleBannerPicture }}
                    titleColor={color}>
                </Title>
                <LocalizedLink to={buttonUrl}>
                    <PrimaryButton
                        backgroundColor='#227EE6'
                        color='#ffffff'
                        borderColor='#227EE6'
                        hoverColor='#ffffff'
                        hoverBorderColor='#ffffff'
                        titleButton={titleButton} />
                </LocalizedLink>
            </CellContent>
            <PictureBanner image={getImage(backgroundBanner)}
                alt={alt}/>
        </Cell>

    );
};

BannerPicture.defaultProps = {color: 'white'};

BannerPicture.propTypes = {
    color: PropTypes.string,
    titleBannerPicture: PropTypes.string,
    titleButton: PropTypes.string,
    backgroundBanner: PropTypes.object,
    buttonUrl: PropTypes.string,
    alt: PropTypes.string
};

export default React.memo(BannerPicture);
